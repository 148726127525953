

.background-image {
    background-image: url('/src/assets/cover_image.jpg'); /* Replace with the path to your image */
    background-size: cover;
    background-position: center;
    /* Add other background properties as needed */
  }

  .option-no-hover:hover {
    background-color: black; /* Remove hover color */
    color: white; /* Keep the text color unchanged */
  }
  